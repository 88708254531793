<template>
  <b-card
    v-if="Object.keys(kandidat).length > 0"
    no-body
  >
    <b-card-body
      :title="kandidat.ime + ' ' + kandidat.prezime"
    >
      <b-nav align="right">
        <b-nav-item>
          <router-link :to="{name: 'autoskola-kandidati-izmeni', params: { id: kandidat.id }}">
            <b-badge :variant="activeOsnovniPodaci">
              {{ $t('OsnovniPodaci') }}
            </b-badge>
          </router-link>
        </b-nav-item>
        <b-nav-item active>
          <router-link :to="{name: 'autoskola-kandidat-ugovori', params: { id: kandidat.id }}">
            <b-badge :variant="activeUgovori">
              {{ $t('Ugovori') }}
            </b-badge>
          </router-link>
        </b-nav-item>
        <b-nav-item active>
          <router-link :to="{name: 'autoskola-kandidat-podaci-o-obuci-izmeni', params: { id: kandidat.id }}">
            <b-badge :variant="activePodaciOObuci">
              {{ $t('PodaciOObuci') }}
            </b-badge>
          </router-link>
        </b-nav-item>
        <b-nav-item active>
          <router-link :to="{name: 'autoskola-kandidat-finansije', params: { id: kandidat.id }}">
            <b-badge :variant="activeFinansije">
              {{ $t('Finansije') }}
            </b-badge>
          </router-link>
        </b-nav-item>
      </b-nav>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BNav, BNavItem, BCard, BCardBody, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BNav,
    BNavItem,
    BCard,
    BCardBody,
    BBadge,
  },
  props: {
    kandidat: {
      type: Object,
      default() {
        return {}
      },
    },
    activeItem: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data: () => ({
    activeOsnovniPodaci: '',
    activeUgovori: '',
    activePodaciOObuci: '',
    activeFinansije: '',
    count: 1,
  }),
  mounted() {
    this.activeOsnovniPodaci = this.activeItem === 'osnovni_podaci' ? 'primary' : 'light-secondary'
    this.activeUgovori = this.activeItem === 'ugovori' ? 'primary' : 'light-secondary'
    this.activePodaciOObuci = this.activeItem === 'podaci_o_obuci' ? 'primary' : 'light-secondary'
    this.activeFinansije = this.activeItem === 'finansije' ? 'primary' : 'light-secondary'
  },
}
</script>
