<template>
  <div>
    <kandidat-menu
      :kandidat="kandidatData"
      :active-item="activeItem"
    />
    <b-card no-body>
      <b-card-body>
        <router-link :to="{ name: 'autoskola-kandidat-ugovori-kreiraj' }">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
          >
            {{ $t("DodajUgovor") }}
          </b-button>
        </router-link>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-body :title="$t('Ugovori')">
        <div>
          <b-table
            v-if="Object.entries(items).length"
            :items="items"
            :fields="fields"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'autoskola-kandidat-ugovori-izmeni',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
                <b-button
                  variant="danger"
                  size="sm"
                  @click="modalBrisanje(data.item.id)"
                >
                  {{ $t("Obriši") }}
                </b-button>
                <b-button
                  variant="info"
                  size="sm"
                  @click="preuzmiUgovor(data.item.id)"
                >
                  {{ $t("Preuzmi") }}
                </b-button>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  {{ $t("SakrijDetalje") }}
                </b-button>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaKreiranihUgovora') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-brisanje-ugovora"
      ref="modal-brisanje-ugovora"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :ok-title="$t('Obrisi')"
      :cancel-title="$t('Otkazi')"
      centered
      :title="$t('ObrisiUgovor')"
      size="lg"
      @ok="obrisiUgovor"
    >
      <div class="d-block text-left">
        <p>
          {{ $t('DaLiSteSigurniDaZeliteDaObriseteUgovor?') }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BButtonGroup,
  BCard,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import KandidatMenu from '../kandidati/KandidatMenu.vue'

export default {
  components: {
    'kandidat-menu': KandidatMenu,
    BTable,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      kandidatData: {},
      activeItem: 'ugovori',
      fields: [
        // { key: 'detalji', label: this.$i18n.t('Detalji') },
        { key: 'datum', label: this.$i18n.t('datum') },
        { key: 'nacin_placanja', label: this.$i18n.t('NacinPlacanja') },
        { key: 'rok_realizacije_formatirano', label: this.$i18n.t('RokRealizacije') },
        { key: 'mobilni', label: this.$i18n.t('Mobilni') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      items: [],
      status: [{
        aktivan: this.$i18n.t('Aktivan'), neaktivan: this.$i18n.t('Neaktivan'), probni: this.$i18n.t('Probni'),
      },
      {
        aktivan: 'light-success', neaktivan: 'light-danger', probni: 'light-warning',
      }],
      podaciUgovoraZaBrisanje: {},
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get(`/autoskole-administratori/ugovori/${this.$route.params.id}`).then(response => {
        this.items = response.data.ugovori
        this.kandidatData = response.data.kandidat
      })
    },
    preuzmiUgovor(id) {
      this.$http.get(`/autoskole-administratori/generate-pdf/${id}`, { responseType: 'arraybuffer' }).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Ugovor - ${this.kandidatData.ime} ${this.kandidatData.prezime}.pdf`
        link.click()
        this.$vs.loading.close()
      })
    },
    modalBrisanje(id) {
      this.podaciUgovoraZaBrisanje = {
        id,
      }
      this.$bvModal.show('modal-brisanje-ugovora')
    },
    obrisiUgovor() {
      this.$http.delete(`/autoskole-administratori/ugovori/${this.podaciUgovoraZaBrisanje.id}`).then(() => {
        this.$bvModal.hide('modal-brisanje-ugovora')
        this.pokupiListu()
      })
    },
  },
}
</script>
